import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'use-context-selector';
import { NailArtContext } from '../nailArtContext/NailArtProvider';
import { ProductContext } from '../productContext/ProductProvider';
import styles from './Breadcrumbs.module.scss';

export type BreadcrumbProps = {
  title?: string;
  ancestors?:
    | {
        title: string;
        url: string;
      }[];
  type?: 'product' | 'nailArt' | 'account';
};

export const Breadcrumbs = ({
  title,
  ancestors = [],
  type,
}: BreadcrumbProps) => {
  let currentTitle = title;
  const currentAncestors = ancestors;
  const productContext = useContext(ProductContext);
  const { query, route } = useRouter();
  const nailArtContext = useContext(NailArtContext);

  /**
   * Sets the ancestors dynamically from route and product data.
   */
  function setProductAncestors() {
    if (
      !Array.isArray(query.handle) ||
      (Array.isArray(query.handle) && query.handle.length <= 1)
    ) {
      return;
    }

    query.handle.forEach((handle, index, query) => {
      if (index === query.length - 1) {
        return;
      }
      currentAncestors.push({
        title: createTitleFromHandle(handle),
        url: `/collections/${handle}`,
      });
    });
  }

  /**
   * Sets the product title
   */
  function setProductTitle() {
    if (productContext === undefined) {
      throw new Error(
        'Breadcrumbs of type product should be nested within Product Provider'
      );
    }
    const { title: productTitle } = productContext;
    currentTitle = productTitle;
  }

  function setNailArtTitle() {
    if (nailArtContext === undefined) {
      throw new Error(
        'Breadcrumbs of type nailArt should be nested within NailArtContext provider'
      );
    }
    const { title: nailArtTitle } = nailArtContext;
    currentTitle = nailArtTitle;
  }

  /**
   * Builds a title from a given handle.
   * @param handle {String}
   * @returns {String}
   */
  function createTitleFromHandle(handle: string) {
    const collection = productContext?.collections?.find(
      (collection) => collection.handle === handle
    );

    if (collection) {
      return collection.title;
    }

    return '';
  }

  function setAccountData() {
    switch (route) {
      // Orders
      case '/account/orders':
        currentTitle = t({
          id: 'breadcrumbs.myOrders',
          message: 'My Orders',
        });
        currentAncestors.push({
          title: t({
            id: 'breadcrumbs.myAccount',
            message: 'My Account',
          }),
          url: '/account',
        });
        break;

      // Single order
      case '/account/orders/[order]':
        currentTitle = t({
          id: 'breadcrumbs.order',
          message: 'Order',
        });
        currentAncestors.push({
          title: t({
            id: 'breadcrumbs.myAccount',
            message: 'My Account',
          }),
          url: '/account',
        });
        currentAncestors.push({
          title: t({
            id: 'breadcrumbs.myOrders',
            message: 'My Orders',
          }),
          url: '/account/orders',
        });
        break;

      // Addresses
      case '/account/addresses':
        currentTitle = t({
          id: 'breadcrumbs.myAddresses',
          message: 'My Addresses',
        });
        currentAncestors.push({
          title: t({
            id: 'breadcrumbs.myAccount',
            message: 'My Account',
          }),
          url: '/account',
        });
        break;

      // Wishlist
      case '/account/wishlist':
        currentTitle = t({
          id: 'breadcrumbs.wishlist',
          message: 'Your Wishlist',
        });
        currentAncestors.push({
          title: t({
            id: 'breadcrumbs.myAccount',
            message: 'My Account',
          }),
          url: '/account',
        });
        break;

      default:
        currentTitle = t({
          id: 'breadcrumbs.myAccount',
          message: 'My Account',
        });
    }
  }

  if (type === 'product') {
    setProductTitle();
    setProductAncestors();
  } else if (type === 'nailArt') {
    setNailArtTitle();
  } else if (type === 'account') {
    setAccountData();
  }

  return (
    <div className={styles.breadcrumbs}>
      <nav
        className="container"
        aria-label={t({
          id: 'breadcrumbs.navigation',
          message: 'breadcrumb navigation',
        })}
      >
        {/* Home link always displays */}
        <Link
          href="/"
          className={clsx(
            styles.breadcrumbs__link,
            styles.breadcrumbs__home,
            'text-utility-utility-small'
          )}
        >
          <Trans id="breadcrumbs.home">Home</Trans>
        </Link>
        {currentAncestors.length > 0 &&
          currentAncestors.map((ancestor, index) => {
            if (ancestor.title !== '') {
              return (
                <Link
                  className={clsx(
                    styles.breadcrumbs__link,
                    'text-utility-utility-small'
                  )}
                  key={index}
                  href={ancestor.url}
                >
                  {ancestor.title}
                </Link>
              );
            }
          })}

        {/* Title becomes a non-clickable string at the end. */}
        {currentTitle && (
          <span
            className={clsx(
              styles.breadcrumbs__link,
              styles.breadcrumbs__current,
              'text-utility-utility-small'
            )}
            aria-current="page"
          >
            {currentTitle}
          </span>
        )}
      </nav>
    </div>
  );
};
