import type { NailArtProductGroupInputProps } from 'components/nailArtContent/NailArtContentProducts';
import type { NailArtProductGroupsFragment } from 'lib/contentful/__generated__/NailArtProductGroup';

export const formatNailArtProductGroup = (
  entryContent: NailArtProductGroupsFragment
): NailArtProductGroupInputProps => {
  const products = [] as NailArtProductGroupInputProps['products'];

  if (entryContent.productsCollection?.items) {
    entryContent.productsCollection.items.forEach((productData) => {
      if (!productData?.handle) return;

      products.push({
        handle: productData.handle,
      });
    });
  }

  return {
    title: entryContent.title ?? '',
    products,
  };
};
