/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import type { TryShadeSectionProps } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { createContext, useContext } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import { CustomerContext } from 'components/customerContext/CustomerContext';
import type {
  StoreProduct,
  StoreProductVariant,
  ColorSystem,
} from 'components/productContext/types/storeProduct';
import { defaultStoreProduct } from 'components/productContext/utils/stubs';
import type { Maybe } from 'components/storeContext/storeMachine';
import { useProductQuery } from 'lib/contentful/__generated__/ProductQuery';
import type { ProductQueryVariables } from 'lib/contentful/__generated__/ProductQuery';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import { useGetColorSystemsQuery } from 'lib/shopify-storefront/__generated__/GetColorSystems';
import type { GetColorSystemsQueryVariables } from 'lib/shopify-storefront/__generated__/GetColorSystems';
import { useGetProductQuery } from 'lib/shopify-storefront/__generated__/GetProduct';
import type { GetProductQueryVariables } from 'lib/shopify-storefront/__generated__/GetProduct';
import type { GetTryShadeSectionQueryVariables } from 'lib/shopify-storefront/__generated__/TryShadeSection';
import { useGetTryShadeSectionQuery } from 'lib/shopify-storefront/__generated__/TryShadeSection';
import { storefrontQueryDataSource } from 'lib/shopify-storefront/dataSources';
import {
  getProductQuerySelect,
  getColorSystemsQuerySelect,
  getProductSectionsQuerySelect,
  getTryShadeSectionQuerySelect,
} from 'utils/format/product';
import type { FormattedContentfulSection } from 'utils/types/contentfulSections';
import parseHandleParam from '../route/products/utils/productSSRprops';
import { localeToCountryLanguage } from '../route/utils/localeToCountryLanguage';

export type ProductContext = StoreProduct & {
  colorSystems: ColorSystem[];
  isLoading: boolean;
  handle: string;
  proCustomer: boolean;
  selectedVariant: Maybe<StoreProductVariant>;
  selectVariant(variant: StoreProductVariant): void;
  url?: string;
  sectionCount?: number;
  sections?: FormattedContentfulSection[];
  tryShadeSection?: TryShadeSectionProps;
  title?: string;
};

export const ProductContext = createContext<ProductContext | undefined>(
  undefined
);

export function ProductProvider({
  children,
}: {
  /** A `ReactNode` element. */
  children: ReactNode;
}) {
  const router = useRouter();
  const { proCustomer } = useContext(CustomerContext);

  const preview =
    typeof router.query.preview === 'string' ? !!router.query.preview : false;

  const { handle: queryHandle } = router.query;

  const defaultHandle = parseHandleParam(queryHandle);

  const deepestHandle = defaultHandle[defaultHandle.length - 1];

  const locale = storeLocale(router.locale || router.defaultLocale);

  // Append `-pro` to handles for pro customers for the Shopify request
  const handle = proCustomer ? `${deepestHandle}-pro` : deepestHandle;

  // For contentful, use the handle without appending `-pro`
  const contentfulHandle = deepestHandle;

  const localeCodes = localeToCountryLanguage(locale);

  // Keep internal state for selected variant
  const [selectedVariant, setSelectedVariant] =
    useState<Maybe<StoreProductVariant>>(null);

  // Select variable action
  const selectVariant = (variant: StoreProductVariant) => {
    setSelectedVariant(variant);
  };

  // Set default value of productData
  const [productData, setProductData] = useState<StoreProduct>({
    ...defaultStoreProduct,
  });

  /**
   * Query Product data from Shopify
   *
   * Setting product query variables then performing the query
   */
  const getProductQueryVariables: GetProductQueryVariables = {
    handle,
    includeReferenceMetafieldDetails: true,
    ...localeCodes,
  };

  const { data: productQueryData, isLoading: isLoadingProductQuery } = useQuery(
    useGetProductQuery.getKey(getProductQueryVariables),
    useGetProductQuery.fetcher(
      storefrontQueryDataSource(locale),
      getProductQueryVariables
    ),
    {
      select: getProductQuerySelect,
      keepPreviousData: true,
    }
  );

  const colorProduct = productData.colorProduct;
  const productType = productData.productType;
  const collectionTag = productData.tags.find((tag) =>
    tag.startsWith('collection:')
  );

  const collections = productQueryData?.collections ?? [];
  const accountType = proCustomer ? 'pro' : 'consumer';
  const productQuery = `product_type:"${productType}" AND tag:"${collectionTag}" AND tag:"${accountType}"`;

  /**
   * Query Colour System data
   *
   * This query is triggered only if colourSystemId is truthy using the
   * enabled option
   */
  const colourSystemQueryVariables: GetColorSystemsQueryVariables = {
    productQuery,
    ...localeCodes,
  };

  /**
   * If product color system is configured:
   * - product is a color product.
   * - product type is set up.
   * - collection tag is set up.
   */
  const colorSystemConfigured =
    colorProduct && !!productType && !!collectionTag;

  const { data: colorSystems = [], isLoading: isLoadingColorSystem } = useQuery(
    useGetColorSystemsQuery.getKey(colourSystemQueryVariables),
    useGetColorSystemsQuery.fetcher(
      storefrontQueryDataSource(locale),
      colourSystemQueryVariables
    ),
    {
      select: getColorSystemsQuerySelect,
      enabled: colorSystemConfigured,
      /**
       * We keep previous data just in case the current
       * visited product has colorSystemConfigured equal true
       */
      keepPreviousData: colorSystemConfigured,
    }
  );
  const tryShadeEnabled = productData.tryShadeEnabled;
  const productTitle = productData.title;
  const tryShadeProductQuery = `NOT product_type:"${productType}" AND title:"${productTitle}" AND tag:"${collectionTag}" AND tag:"${accountType}"`;
  /**
   * If product try shade section is configured:
   * - try shade section is enabled (metafield).
   * - product type is set up.
   * - collection tag is set up.
   */
  const tryShadeConfigured =
    tryShadeEnabled && !!productType && !!collectionTag;

  /**
   * Query Try Shade Section data
   *
   * This query is triggered only if tryShadeEnabled is truthy using the
   * enabled option
   */
  const tryShadeSectionQueryVariables: GetTryShadeSectionQueryVariables = {
    productQuery: tryShadeProductQuery,
    includeReferenceMetafieldDetails: true,
    ...localeCodes,
  };

  const { data: tryShadeSection, isLoading: isLoadingTryShade } = useQuery(
    useGetTryShadeSectionQuery.getKey(tryShadeSectionQueryVariables),
    useGetTryShadeSectionQuery.fetcher(
      storefrontQueryDataSource(locale),
      tryShadeSectionQueryVariables
    ),
    {
      select: (rawData) => getTryShadeSectionQuerySelect(rawData, productData),
      enabled: tryShadeConfigured,
      /**
       * We keep previous data just in case the current
       * visited product has tryShadeConfigured equal true
       */
      keepPreviousData: tryShadeConfigured,
    }
  );

  /**
   * Contentful Query
   */
  const contentfulProductQueryVariables: ProductQueryVariables = {
    handle: contentfulHandle,
    locale,
    preview,
  };
  const { data: contentfulSections = [] } = useQuery(
    useProductQuery.getKey(contentfulProductQueryVariables),
    useProductQuery.fetcher(
      contentfulQueryDataSource({ byPassCache: preview }),
      contentfulProductQueryVariables
    ),
    {
      select: (data) => getProductSectionsQuerySelect(data, proCustomer),
    }
  );

  /**
   * Loading State is derived from the loading state of all queries
   */
  const isLoading =
    isLoadingProductQuery || (isLoadingColorSystem && colorSystemConfigured);

  /**
   * Variant selection
   *
   * Variant selection takes places if, loading is complete, there is product
   * data and selectedVariant is falsey
   */
  if (!isLoading && productQueryData && !selectedVariant) {
    selectVariant(productQueryData.variants[0]);
  }

  /**
   * Product data assaignment
   *
   * Product data is re-assaigned if loading is complete, there is product
   * data and the new product data ID does not equal the current product id
   */
  if (
    !isLoading &&
    productQueryData &&
    productQueryData.id !== productData.id
  ) {
    setProductData({ ...productData, ...productQueryData });
    selectVariant(productQueryData.variants[0]);
  }

  return (
    <ProductContext.Provider
      value={{
        ...productData,
        collections,
        ...contentfulSections,
        proCustomer,
        handle,
        colorSystems,
        isLoading,
        selectedVariant,
        selectVariant,
        tryShadeSection: {
          ...tryShadeSection,
          isLoading: isLoadingTryShade && tryShadeEnabled,
        },
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export const useProduct = () => {
  return useContext(ProductContext);
};
