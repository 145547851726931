/* eslint-disable sonarjs/cognitive-complexity */
import { t } from '@lingui/macro';
import type {
  TryShadeSectionProps,
  TryShadeCardProps,
} from '@we-make-websites/ui-lib';
import type {
  InlineProductCardProduct,
  ProductCardProduct,
} from '@we-make-websites/ui-lib/components';
import type { ImageType } from 'components/plpContext/types/storePLP';
import type {
  StoreMediaNode,
  StoreMediaImage,
} from 'components/productContext/types/storeMedia';
import { StoreMediaType } from 'components/productContext/types/storeMedia';
import type {
  StoreProduct,
  ColorSystem,
} from 'components/productContext/types/storeProduct';
import { defaultStoreProduct } from 'components/productContext/utils/stubs';
import type { ProductQuery } from 'lib/contentful/__generated__/ProductQuery';
import type { ProductByHandleQuery } from 'lib/shopify-admin/__generated__/ProductByHandle';
import type { GetColorSystemsQuery } from 'lib/shopify-storefront/__generated__/GetColorSystems';
import type { GetProductQuery } from 'lib/shopify-storefront/__generated__/GetProduct';
import type { GetTryShadeSectionQuery } from 'lib/shopify-storefront/__generated__/TryShadeSection';
import type {
  Model3dSource,
  VideoSource,
} from 'lib/shopify-storefront/__generated__/types';
import { formatContentfulSections } from 'utils/format/contentful/formatContentfulSections';
import type {
  FormattedContentfulSection,
  InputContentfulSections,
} from 'utils/types/contentfulSections';
import { getCleanProductHandle } from '../helpers';

/**
 * Select function that acts as 'clean' to GetProductQuery
 *
 * @remarks
 * Data from GetProductQuery is reduced into the shape of StoreProduct
 * This is then made available as the output of data from useQuery
 *
 * @param originalData - accepts GetProductQuery
 */
export const getProductQuerySelect = (
  originalData: GetProductQuery | ProductByHandleQuery
): StoreProduct => {
  const product: StoreProduct = {
    ...defaultStoreProduct,
  };

  if (originalData.product) {
    product.id = originalData.product.id ?? '';
    product.title = originalData.product.title ?? '';
    product.price = {
      amount: Number(originalData.product.priceRange.minVariantPrice.amount),
      currencyCode:
        originalData.product.priceRange.minVariantPrice.currencyCode,
    };
    product.collections = originalData.product.collections.nodes;

    if ('comparePrice' in originalData.product) {
      product.comparePrice = {
        amount: Number(
          originalData.product.compareAtPriceRange.minVariantPrice.amount
        ),
        currencyCode:
          originalData.product.compareAtPriceRange.minVariantPrice.currencyCode,
      };
    }
    product.description = originalData.product.description;

    if ('availableForSale' in originalData.product) {
      product.availableForSale = originalData.product.availableForSale;
    }

    product.hexColour = originalData.product.meta_hex_color?.value || '';
    product.primaryColorFamily =
      originalData.product.meta_primary_color_family?.value || '';
    product.secondaryColorFamily =
      originalData.product.meta_secondary_color_family?.value || '';
    product.colorDepth = originalData.product.meta_depth_of_color?.value || '';
    product.colorFamilySubgroup =
      originalData.product.meta_color_family_subgroup?.value || '';
    product.finish = originalData.product.meta_finish?.value || '';
    product.cadColorOpacity =
      originalData.product.meta_cad_color_opacity?.value || '';
    product.proOnly =
      (originalData.product.meta_pro_only?.value || '').toLowerCase() ===
        'true' || false;
    product.colorSystemLabel =
      originalData?.product.meta_color_collection?.value ?? '';
    product.colorProduct =
      (originalData.product.meta_color_product?.value || '').toLowerCase() ===
        'color product' || false;

    product.tags = originalData.product.tags;
    product.sku = originalData?.product.meta_sku?.value ?? '';
    product.productType = originalData.product.productType ?? '';
    product.ultaProductUrl = originalData?.product.meta_ulta_product_url?.value;
    product.customBuyNowLabel =
      originalData?.product.meta_custom_buy_now_label?.value;
    product.customBuyNowUrl =
      originalData?.product.meta_custom_buy_now_url?.value;
    product.redirectToWsUrl =
      originalData?.product.meta_product_redirect_to_ws_url?.value;
    product.asin = originalData?.product.meta_asin?.value;
    product.tryShadeEnabled =
      (
        originalData?.product.meta_try_shade_enabled?.value || ''
      ).toLowerCase() === 'true';
    product.tryShadeBgColor =
      originalData.product.meta_try_shade_bg?.value || '';

    product.awardsIcons = getAwardsIcons(originalData?.product);
    product.seo = {
      title: originalData.product.seo.title ?? '',
      description: originalData.product.seo.description ?? '',
    };
    product.separatePriceForPro =
      (
        originalData.product.meta_separate_price_for_pro?.value || ''
      ).toLowerCase() === 'true' ?? false;

    product.recommendedEssentials = getRecommendedEssentials(
      originalData.product
    );

    // If products variants walk variants
    if (originalData.product.variants) {
      // Empty array at start of walk
      product.variants = getVaraintsData(originalData.product);
    }

    product.media = getProductMedia(originalData.product);
    product.tabs = getProductTabsData(originalData.product);
  }

  return product;
};

/**
 * Format product variants data.
 */
function getVaraintsData(
  product: GetProductQuery['product'] | ProductByHandleQuery['product']
) {
  const variants = [] as StoreProduct['variants'];

  if (!product?.variants) return variants;

  product.variants.edges.forEach((variant) => {
    let price = '0';
    let comparePrice: string | undefined = undefined;
    let currencyCode: string | undefined = 'USD';

    if ('priceV2' in variant.node) {
      price = variant.node.priceV2.amount;
      comparePrice = variant.node.compareAtPriceV2?.amount;
      currencyCode = variant.node.priceV2.currencyCode;
    } else if ('contextualPricing' in variant.node) {
      price = variant.node.contextualPricing.price.amount;
      currencyCode = variant.node.contextualPricing.price.currencyCode;
      comparePrice = variant.node.contextualPricing.compareAtPrice?.amount;
    }

    variants.push({
      id: variant.node.id,
      price: {
        amount: Number(price),
        currencyCode,
      },
      compareAtPrice: comparePrice
        ? {
            amount: Number(comparePrice),
            currencyCode,
          }
        : undefined,
      bundlePrice: variant.node.bundle_original_price?.value
        ? {
            amount: Number(variant.node.bundle_original_price.value),
            currencyCode,
          }
        : undefined,
      availableForSale: variant.node.availableForSale,
      proOnly: variant.node.meta_pro_only?.value === 'true',
      title: variant.node.title ?? '',
      sku: variant.node.sku ?? '',
      barcode: variant.node.barcode ?? '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedOptions: (variant.node.selectedOptions ?? []) as any,
      image: variant.node.image,
    });
  });

  return variants;
}

/**
 * Format product tabs data.
 */
function getProductTabsData(
  product: GetProductQuery['product'] | ProductByHandleQuery['product']
) {
  const tabs = [] as StoreProduct['tabs'];

  tabs.push({
    name: t({
      id: 'product.description',
      message: 'Description',
    }),
    content: product?.meta_long_description?.value || '',
  });

  tabs.push({
    name: t({
      id: 'product.benefits',
      message: 'Benefits',
    }),
    content: product?.meta_benefits?.value || '',
  });

  tabs.push({
    name: t({
      id: 'product.how_to_use',
      message: 'How to use',
    }),
    content: product?.meta_how_to_apply?.value || '',
  });

  tabs.push({
    name: t({
      id: 'product.ingredients',
      message: 'Ingredients',
    }),
    content: product?.meta_ingredients?.value || '',
  });

  return tabs;
}

/**
 * Format Recommended Essentials.
 */
function getRecommendedEssentials(
  product: GetProductQuery['product'] | ProductByHandleQuery['product']
) {
  const recommendedEssentials: Array<
    ProductCardProduct & InlineProductCardProduct
  > = [];

  if (
    product?.meta_recommended_essentials?.reference?.__typename !== 'Metaobject'
  )
    return recommendedEssentials;

  product?.meta_recommended_essentials?.reference.list?.references?.nodes.forEach(
    (node) => {
      if (!node) {
        return;
      }

      if (node.__typename !== 'Metaobject') return;

      if (node.product?.reference?.__typename !== 'Product') return;

      /** Get first available variant */
      const firstAvailableVariant = node.product.reference.variants.nodes.find(
        (variant) => {
          if ('availableForSale' in variant) {
            return variant.availableForSale;
          }
        }
      );

      const firstImage = node.product.reference.images.nodes[0];

      const productHandle = getCleanProductHandle(
        node.product?.reference.handle
      );

      const url = productHandle ? `/products/${productHandle}` : '';

      let availableForSale = true;

      if ('availableForSale' in node.product.reference) {
        availableForSale = node.product?.reference.availableForSale;
      }

      recommendedEssentials.push({
        systemName: node.product?.reference.systemName ?? '',
        title: node.product?.reference.title ?? '',
        handle: productHandle,
        id: node.product?.reference.id ?? '',
        image: firstImage.url,
        images:
          node.product?.reference.images.nodes.map((image) => ({
            alt: image.altText || '',
            src: image.url || '',
            height: image.height || 0,
            width: image.width || 0,
          })) ?? [], // this data is only used in InlineProductCard inside the NailArtContentProducts
        // but typing forces us to include full data here
        price: {
          amount: Number(
            node.product?.reference.priceRange.minVariantPrice.amount
          ),
          currencyCode:
            node.product?.reference.priceRange.minVariantPrice.currencyCode,
        },
        tags: node.product?.reference.tags,
        proOnly:
          (node.product?.reference.meta_pro_only?.value || '').toLowerCase() ===
            'true' || false,
        url,
        availableForSale,
        firstAvailableVariantId: firstAvailableVariant?.id,
        ultaProductUrl: node.product.reference.meta_ulta_product_url?.value,
        customBuyNowLabel:
          node.product.reference.meta_custom_buy_now_label?.value,
        customBuyNowUrl: node.product.reference.meta_custom_buy_now_url?.value,
        asin: node.product.reference.meta_asin?.value,
        slogan: node.slogan?.value ?? '',
      });
    }
  );

  return recommendedEssentials;
}

/**
 * Format product awards icons.
 */
function getAwardsIcons(
  product: GetProductQuery['product'] | ProductByHandleQuery['product']
) {
  const rawData = product?.meta_awards_icons?.value || '[]';

  try {
    return JSON.parse(rawData);
  } catch (error) {
    return [];
  }
}

/**
 * Handle transformations of product media into a more usable shape.
 *
 * Cognitive complexity warning suppressed to allow for switch statement
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
function getProductMedia(
  product: GetProductQuery['product'] | ProductByHandleQuery['product']
): StoreMediaNode[] {
  if (!product?.media) {
    return [];
  }

  const media: StoreMediaNode[] = [];

  product.media.edges.forEach((edge) => {
    const { node } = edge;

    switch (node.__typename) {
      case 'MediaImage':
        if (node.image)
          media.push({
            type: StoreMediaType.Image,
            id: node.image.id ?? '',
            image: {
              id: node.image.id ?? '',
              url: node.image.url ?? '',
              altText: node.image.altText ?? '',
              width: node.image.width ?? 0,
              height: node.image.height ?? 0,
            },
          });
        break;

      case 'Video':
        media.push({
          type: StoreMediaType.Video,
          id: node.id ?? '',
          video: {
            id: node.id ?? '',
            alt: node.alt ?? '',
            previewImage: null, // node.previewImage?.url ?? null,
            sources: node.sources as VideoSource[],
          },
        });
        break;

      case 'ExternalVideo':
        media.push({
          type: StoreMediaType.ExternalVideo,
          id: node.id ?? '',
          externalVideo: {
            id: node.id ?? '',
            embeddedUrl: node.embeddedUrl ?? '',
            host: node.host ?? '',
          },
        });
        break;

      case 'Model3d':
        media.push({
          type: StoreMediaType.Model3D,
          id: node.id ?? '',
          model3D: {
            id: node.id ?? '',
            alt: node.alt ?? '',
            previewImage: null, // node.previewImage?.url ?? null,
            sources: node.sources as Model3dSource[],
          },
        });
        break;

      default:
        return null;
    }
  });

  return media;
}

/**
 * Select function that acts as 'clean' to GetProductQuery
 *
 * @remarks
 * Data from GetColorSystemsQuery is reduced into the shape of StoreProduct
 * This is then made available as the output of data from useQuery
 *
 * @param originalData - accepts GetColorSystemsQuery
 */
export const getColorSystemsQuerySelect = (
  originalData: GetColorSystemsQuery
) => {
  if (!originalData.products?.nodes) return [];

  const items = originalData.products.nodes;
  const colorSystems: ColorSystem[] = [];

  items.forEach((item) => {
    const swatchUrl = item.images?.nodes[2]?.url ?? '';

    if (!swatchUrl) return;

    const collections = item.collections.nodes.map((collectionNode) => {
      return {
        handle: collectionNode.handle ?? '',
        title: collectionNode.title ?? '',
      };
    });

    colorSystems.push({
      handle: item.handle,
      title: item.title,
      cadColorOpacity: item.cadColorOpacity?.value,
      sku: item.sku?.value,
      swatchUrl,
      collections,
    });
  });

  return colorSystems;
};

/**
 * Select function that acts as 'clean' to GetTryShadeSectionQuery
 *
 * @remarks
 * Data from GetTryShadeSectionQuery is reduced into the shape of TryShadeSectionProps
 * This is then made available as the output of data from useQuery
 *
 * @param originalData - accepts GetTryShadeSectionQuery
 * @param visitedProduct - PDP product - StoreProduct
 */
export const getTryShadeSectionQuerySelect = (
  originalData: GetTryShadeSectionQuery,
  visitedProduct: StoreProduct
): TryShadeSectionProps | undefined => {
  if (!originalData.products?.nodes) return undefined;

  const tryShadeCards: TryShadeCardProps[] = [];
  const items = originalData.products.nodes;

  if (items.length) {
    items.forEach((product) => {
      const cardData = formatTryShadeCard(product);

      if (!cardData) {
        return;
      }

      tryShadeCards.push(cardData);
    });
  }

  // Get 2nd image.
  const tryShadeImage = visitedProduct.media.find(
    (image, index) => image.type === StoreMediaType.Image && index === 1
  ) as StoreMediaImage | undefined;

  return {
    backgroundColor: visitedProduct.tryShadeBgColor ?? '',
    image: tryShadeImage
      ? {
          title: tryShadeImage.image.altText || '',
          url: tryShadeImage.image.url || '',
          height: tryShadeImage.image.height || 0,
          width: tryShadeImage.image.width || 0,
        }
      : undefined,
    tryShadeCards,
    isLoading: false,
  };
};

const formatTryShadeCard = (
  product: GetTryShadeSectionQuery['products']['nodes'][0]
): TryShadeCardProps | undefined => {
  const productImage = getProductPackshot(product);

  if (!productImage) return;

  const isProOnlyProduct =
    (product.meta_pro_only?.value || '').toLowerCase() === 'true';

  const tagline = isProOnlyProduct
    ? t({
        id: 'tryShadeSection.proOnlyTagline',
        message: 'pro only',
      })
    : '';

  const ctaTitle = isProOnlyProduct
    ? t({
        id: 'tryShadeSection.proOnlyCTA',
        message: 'browse now',
      })
    : t({
        id: 'tryShadeSection.CTA',
        message: 'shop now',
      });

  return {
    title: product.title ?? '',
    tagline,
    productType: product.productType ?? '',
    productHandle: product.handle ?? '',
    productImage,
    icons: [],
    ctaTitle,
    backgroundColor: product.meta_hex_color?.value
      ? `#${product.meta_hex_color?.value}`
      : '',
    textTheme:
      product.meta_try_shade_theme?.value === 'light' ? 'light' : 'dark',
  };
};

/**
 * Format product packshot image.
 */
function getProductPackshot(
  product: GetTryShadeSectionQuery['products']['nodes'][0]
): ImageType | undefined {
  if (!product.media?.nodes?.length) return undefined;

  const packshotImage = product.media?.nodes?.find((mediaItem) => {
    if (
      mediaItem.__typename === 'MediaImage' &&
      mediaItem.image?.altText?.includes('-pack_shot')
    ) {
      return mediaItem;
    }
  }) as StoreMediaImage | undefined;

  return packshotImage
    ? {
        url: packshotImage.image.url,
        title: packshotImage.image.altText,
        width: packshotImage.image.width,
        height: packshotImage.image.height,
      }
    : undefined;
}

/**
 * Format the query data to be used in the context.
 * @param originalData - The original data from the query
 */
export const getProductSectionsQuerySelect = (
  originalData: ProductQuery,
  proContent = false
):
  | {
      sectionCount: number;
      sections: FormattedContentfulSection[];
    }
  | false => {
  if (!originalData.productCollection) {
    return false;
  }

  let sections: FormattedContentfulSection[] = [];

  if (originalData.productCollection.items[0]?.sectionsCollection?.items) {
    sections = formatContentfulSections(
      originalData.productCollection.items[0]?.sectionsCollection
        ?.items as InputContentfulSections,
      proContent
    );
  }

  return {
    sectionCount:
      originalData.productCollection.items[0]?.sectionsCollection?.total ?? 0,
    sections,
  };
};
