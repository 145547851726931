import type { ProductContext } from 'components/productContext/ProductProvider';
import { StoreMediaType } from 'components/productContext/types/storeMedia';
import type { StoreProduct } from 'components/productContext/types/storeProduct';
import { CurrencyCode } from 'lib/shopify-storefront/__generated__/types';

export const defaultStoreProduct: StoreProduct = {
  title: '',
  id: '',
  collections: [],
  price: null,
  description: '',
  separatePriceForPro: false,
  hexColour: '#ffffff',
  handle: '',
  cadColorOpacity: 'Opaque',
  proOnly: false,
  colorProduct: false,
  colorSystemLabel: '',
  variants: [],
  tags: [],
  productType: '',
  media: [],
  tabs: [],
  sku: '',
  awardsIcons: [''],
  reviews: {
    count: 0,
    rating: 0,
  },
  seo: {
    title: '',
    description: '',
  },
  tryShadeEnabled: false,
  finish: '',
  availableForSale: true,
  recommendedEssentials: [],
};

export const dummyProductContext: ProductContext = {
  id: '',
  finish: '',
  price: {
    amount: 9.99,
    currencyCode: CurrencyCode.Gbp,
  },
  collections: [],
  availableForSale: true,
  colorProduct: false,
  colorSystems: [
    {
      handle: 'amazon-amazoff-opi-nail-lacquer',
      title: 'AmazON…AmazOFF',
      swatchUrl:
        'https://cdn.shopify.com/s/files/1/0649/4879/7673/products/15-minutes-of-flame-islh011-long-lasting-nail-polish-99350070072.jpg?v=1667948927',
      collections: [],
    },
    {
      handle: 'taupe-less-beach-opi-nail-lacquer',
      title: 'Taupe-less Beach',
      swatchUrl:
        'https://cdn.shopify.com/s/files/1/0649/4879/7673/products/15-minutes-of-flame-islh011-long-lasting-nail-polish-99350070072.jpg?v=1667948927',
      collections: [],
    },
  ],
  handle: 'amazon-amazoff-opi-nail-lacquer',
  url: 'https://opi-development.vercel.app/products/amazon-amazoff-opi-nail-lacquer',
  media: [
    {
      id: '1',
      type: StoreMediaType.Image,
      image: {
        id: '1',
        url: 'https://cdn.shopify.com/s/files/1/0571/1996/5257/products/amazon-amazoff-nla64-nail-lacquer-22002576012_29_1_0_0_aecd114b-af59-46a3-a05c-6f50ec5e297b.jpg',
        altText: 'test',
        width: 483,
        height: 624,
      },
    },
    {
      id: '2',
      type: StoreMediaType.Image,
      image: {
        id: '2',
        url: 'https://cdn.shopify.com/s/files/1/0571/1996/5257/products/amazon-amazoff-brush-swatch_15_1_0_0_00b482a7-d5d6-4c7e-b940-af223c8d311f.jpg?v=1652705173',
        altText: 'test',
        width: 483,
        height: 624,
      },
    },
    {
      id: '3',
      type: StoreMediaType.Image,
      image: {
        id: '3',
        url: 'https://cdn.shopify.com/s/files/1/0571/1996/5257/products/amazon-amazoff-mani_15_1_0_0_50c8c15e-b3f5-403c-8fee-c5653fc96c14.jpg?v=1652705173',
        altText: 'test',
        width: 483,
        height: 624,
      },
    },
    {
      id: '4',
      type: StoreMediaType.Image,
      image: {
        id: '4',
        url: 'https://cdn.shopify.com/s/files/1/0571/1996/5257/products/amazon-amazoff-gif_5_1_0_0_a5543ebf-3bcf-40ea-83e4-c501d0292dac.gif?v=1652705173',
        altText: 'test',
        width: 483,
        height: 624,
      },
    },
  ],
  tags: [],
  productType: 'Nail Lacquer',
  proOnly: false,
  separatePriceForPro: true,
  tryShadeEnabled: false,
  variants: [
    {
      title: 'small bottle',
      price: { amount: 9.99, currencyCode: CurrencyCode.Gbp },
      compareAtPrice: undefined,
      id: 'xxx',
      proOnly: false,
      availableForSale: true,
      selectedOptions: [],
      sku: 'xxx001',
      barcode: '123456789',
    },
    {
      title: 'large bottle',
      price: { amount: 12.99, currencyCode: CurrencyCode.Gbp },
      compareAtPrice: undefined,
      id: 'xxl',
      proOnly: false,
      availableForSale: true,
      selectedOptions: [],
      sku: 'xxl001',
      barcode: '123456789',
    },
  ],
  selectedVariant: {
    id: 'xxx',
    price: { amount: 9.99, currencyCode: CurrencyCode.Gbp },
    compareAtPrice: undefined,
    title: 'Size',
    proOnly: false,
    availableForSale: true,
    selectedOptions: [
      {
        name: 'Size',
        value: 'size',
      },
    ],
    sku: 'xxx001',
    barcode: '123456789',
  },
  isLoading: false,
  proCustomer: false,
  title: 'Orangey Orange',
  description: 'some benefits',
  hexColour: '',
  cadColorOpacity: '',
  colorSystemLabel: 'ProSpa',
  sku: 'AS200',
  awardsIcons: [''],
  reviews: {
    count: 24,
    rating: 4.5,
  },
  seo: {
    title: 'Title',
    description: 'Description',
  },
  tabs: [
    {
      name: 'Description',
      content: `Discover lush and luminous color with this fuchsia nail polish.
        Show off your flair for the dramatic with this Nail Lacquer.
        Nail Lacquer is OPI's original nail polish formula.
        Use your nail polish shade with OPI base coat and top coat for extended wear.
        Made in the USA.`,
    },
    {
      name: 'Benefits',
      content: 'some benefits',
    },
  ],
  recommendedEssentials: [],
  selectVariant: () => {
    return null;
  },
};
