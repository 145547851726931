import type { ImageType } from '@we-make-websites/ui-lib/src';
import type { StoreNailArt } from 'components/nailArtContext/types/storeNailArt';
import type { NailArtProductGroupsFragment } from 'lib/contentful/__generated__/NailArtProductGroup';
import type { NailArtQuery } from 'lib/contentful/__generated__/NailArtQuery';
import type { InputContentfulSections } from '../types/contentfulSections';
import { formatContentfulSections } from './contentful/formatContentfulSections';
import { formatNailArtInstructionStep } from './contentful/formatNailArtInstructionSteps';
import { formatNailArtProductGroup } from './contentful/formatNailArtProductGroups';
import { formatVideoSection } from './contentful/formatVideoSection';

/* eslint-disable sonarjs/cognitive-complexity */
export const formatContentfulNailArt = (
  originalData: NailArtQuery
): StoreNailArt | null => {
  if (!originalData?.nailArtCollection?.items) return null;

  const nailArt: StoreNailArt = {
    title: '',
    proOnly: false,
  };

  const [nailArtData] = originalData.nailArtCollection.items;

  if (!nailArtData) return null;

  nailArt.seo = {
    title: nailArtData.seoTitle,
    description: nailArtData.seoDescription,
  };
  nailArt.title = nailArtData.title ?? '';
  nailArt.description = nailArtData.description ?? '';
  nailArt.instructions = nailArtData.instructions?.url ?? '';
  nailArt.proOnly = Boolean(nailArtData.proOnly);

  /**
   * Handle images
   */
  if (nailArtData.images?.items) {
    const images = [] as ImageType[];
    nailArtData.images.items.forEach((imageData) => {
      if (!imageData?.url) return;

      images.push({
        url: imageData.url ?? '',
        width: imageData.width ?? 0,
        height: imageData.height ?? 0,
        title: imageData.title ?? '',
      });
    });

    nailArt.images = images;
  }

  /**
   * Handle product up-sell groups
   */
  if (nailArtData.productGroups?.items) {
    const validProductGroupsData = nailArtData.productGroups.items.filter(
      (item) => Boolean(item)
    );

    const formattedNailArtProductGroups = validProductGroupsData.map(
      (productGroupData) =>
        formatNailArtProductGroup(
          productGroupData as NailArtProductGroupsFragment
        )
    );

    nailArt.productGroups = formattedNailArtProductGroups;
  }

  /**
   * Handle product up-sell groups
   */
  if (nailArtData.steps?.items) {
    const validStepsData = nailArtData.steps.items.filter((item) =>
      Boolean(item)
    );

    const formattedNailArtSteps = validStepsData.map((stepData, index) => {
      const stepDataWithIndex = {
        ...stepData,
        index: index + 1,
      };

      return formatNailArtInstructionStep(stepDataWithIndex);
    });

    nailArt.steps = formattedNailArtSteps;
  }

  /**
   * Handle video
   */
  nailArt.video = nailArtData.video
    ? formatVideoSection(nailArtData.video)
    : undefined;

  /**
   * Handle sections
   */
  if (nailArtData.sections?.items) {
    const formattedSections = formatContentfulSections(
      nailArtData.sections?.items as InputContentfulSections
    );

    nailArt.sections = formattedSections;
  }

  return nailArt;
};

/**
 * To do:
 * - proOnly - wait for data mapping confirmation
 * - productType - wait for data mapping confirmation
 */
