import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { NailArtStepProps } from '@we-make-websites/ui-lib/src';
import type { NailArtInstructionStepFragment } from 'lib/contentful/__generated__/NailArtInstructionStep';

export const formatNailArtInstructionStep = (
  entryContent: NailArtInstructionStepFragment & {
    index: number;
  }
): NailArtStepProps => {
  return {
    index: entryContent.index,
    content: formatContent(entryContent.content),
    image: entryContent.image
      ? {
          url: entryContent.image.url ?? '',
          width: entryContent.image.width ?? 0,
          height: entryContent.image.height ?? 0,
          title: entryContent.image.title ?? '',
        }
      : undefined,
  };
};

const formatContent = (
  unformattedContent: NailArtInstructionStepFragment['content']
) => {
  if (!unformattedContent) return;

  const jsonData = unformattedContent.json as Document;
  return documentToReactComponents(jsonData);
};
